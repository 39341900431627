.des {
    margin-top: 10px!important;
    margin-top: 10px!important;
    font-size: 15px!important;
    text-transform: none!important;
    color: white!important;
}

.name {
    font-size: 20px!important;
}

.version {
    z-index: 1;
    color: #e2c696;
    font-size: 14px!important;
  }
  
.prof-container {
    margin: 0px!important;
    margin-bottom: 5px!important;
}