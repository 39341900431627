.border-radius-2 div {
  border-radius: 15px !important;
}
.my-modal-body {
  background: url("../../assets/Simon-game-leader-board/Group-3001@2x.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.my-modal-body .card-container {
  padding: 20px;
}

.my-modal-body .card-container .top-btn-section {
  width: 100%;
  cursor: pointer;
}

.my-modal-body .card-container .top-btn-section .close-btn {
  width: 60px;
  height: 60px;
  background: url("../../assets/Simon-game-leader-board/Ellipse15@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  right: -10px;
}

.my-modal-body .card-container .star-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-modal-body .card-container .star-section .left-star {
  padding-top: 15px;
  padding-right: 10px;
}
.my-modal-body .card-container .star-section .right-star {
  padding-top: 15px;
  padding-left: 10px;
}

.my-modal-body .card-container .score-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
}

.my-modal-body .card-container .score-section .name {
  font-weight: bold;
  text-transform: capitalize;
  color: #dd8722;
}

.my-modal-body .card-container .score-section .score {
  font-size: 55px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  color: #0a79f7;
}

.my-modal-body .card-container .score-section .board-played {
  color: #f5a52b;
  font-weight: bold;
}

.my-modal-body .card-container .top-three-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.my-modal-body .card-container .top-three-section .player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-modal-body .card-container .top-three-section .player .avatar-name {
  font-weight: bold;
  color: #9f988a;
  font-size: 14px;
}

.my-modal-body .card-container .top-three-section .player .avatar-score {
  font-weight: bold;
  color: #f5a429;
  font-size: 14px;
}

.my-modal-body .card-container .lives-section {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.my-modal-body .card-container .lives-section .no-lives {
  color: #f5a429;
  font-size: 25px;
}

.my-modal-body .card-container .btn-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: -20px;
}

.my-modal-body .card-container .btn-section .back-btn {
  width: 70px;
  height: 70px;
  background: url("../../assets/Simon-game-leader-board/Ellipse15@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: -70px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-modal-body .card-container .btn-section .retry-btn {
  width: 70px;
  height: 70px;
  background: url("../../assets/Simon-game-leader-board/Ellipse15@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blink {
  animation: blinking 0.5s infinite;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow-bounce {
  animation: sidebounce 1.5s infinite;
}

@keyframes sidebounce {
  0% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(20%);
  }
  50% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(0px);
  }
}

.zoomIn {
  animation: zoomInOut 1s infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.09);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
