html
{
  height: 100%;
  margin: 0;
  padding: 0;
  
}

body {
  margin: 0;  
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 background: url(assets/bg.png) no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 height: 100%;
 margin: 0;
  padding: 0;
}

code {
 font-family: "source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace";
}
 
.App .container
{
 padding-top: 0px;
}

.locked-item
{
  cursor:default !important;
}

.header-block
{
 margin-bottom: 150px;
 padding-top: 15px;
 padding-bottom: 15px;
}

.default-list-button
{
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   
   font-size: 26px;
   margin: 10px;
   padding: 10px 10px;
   color: #333;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
}



.questions-text-button
{
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
  
   font-size: 65px;
   margin: 10px 10px 50px 10px;
   padding: 10px 10px;
   color: #fff;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);    
   display: flex;
   justify-content: center;
   align-items: center;
}

.header-side-buttons
{
   
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
   font-size: 32px;
   color: #fff;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);
   cursor: pointer;
}

.header-first-btn
{
 width: 50%;
 text-align: center;
 display: inline-block;
}

.header-middle-btn
{
 width: 80%;
 text-align: center;
 display: inline-block;
}


.header-end-btn
{
 width: 50%;
 text-align: center;
 display: inline-block;
}

.header-disabled-button
{
   color: #bbb !important;
   cursor: default !important;
}

.header-middle-button
{
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;    
   font-size: 46px;
   color: #fff;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);
   cursor: pointer;
}

 /* Header */
 .header-first
 {
 
   text-align: left;
   display: flex;
   justify-content: flex-start;
   align-items: center;
 }

 .header-first-btn 
 {
   width: 100%;
   text-align: center;
   display: inline-block;
 }

 .header-middle
 {
   align-items: center;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .header-end
 {
 
   text-align: right;
   display: flex;
     justify-content: flex-end;
     align-items: center;
 }

 .header-end-btn {
   width: 100%;
   text-align: center;
   display: inline-block;
 }

/* Questions Screen */
.questions-header
{
 display: flex; 
 flex-direction: row;
 justify-content: space-between;
 align-items:center;
}

.audio-button, .lessons-link, .answers-meter
{
 display: inline-block;
}

.audio-button img
{
 width: 75px;
 cursor: pointer;

}

.links-xs
{
 display: none;
}

.links-xl
{
 display: block;
}

.arrow-buttons-block
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.default-answer-box
{
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
   height: 300px;
   font-size: 200px;
   color: #fff;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);
   position: relative;
   display: flex; 
   flex-direction: row;
   justify-content: center;
   align-items:center;
   padding: 10px;
   margin: 10px;

}

.unanswered-answer-box
{
   background-image: url("./assets/answer_box.png") !important;
   cursor: pointer;
}

.unanswered-answer-box:active:hover 
{
 background-image: url("./assets/answer_box_pressed.png") !important;
 cursor: pointer;
}




.answer-boxes
{
 position: relative;
}

.crossTick
{
 position: absolute;
 left: 1px;
 height: 18px;
 top: 1px;
}

.default-text-button
{
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
   width: 100%;
   height: 100px;
   font-size: 55px;
   margin: 10px;
   padding: 10px 10px;
   color: #fff;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
}

.unanswered-text-button
{
   background-image: url("./assets/button_normal.png");  
}

.unanswered-text-button:active:hover 
{
 background-image: url("./assets/button_pressed.png");
}


@-webkit-keyframes fadeInLeft {
 from {
   opacity: 0;
   -webkit-transform: translate3d(-50%, 0, 0) !important;
   transform: translate3d(-50%, 0, 0) !important;
 }

 to {
   opacity: 1;
   -webkit-transform: translate3d(0, 0, 0) !important;
   transform: translate3d(0, 0, 0) !important;
 }
}
@keyframes fadeInLeft {
 from {
   opacity: 0;
   -webkit-transform: translate3d(-50%, 0, 0) !important;
   transform: translate3d(-50%, 0, 0) !important;
 }

 to {
   opacity: 1;
   -webkit-transform: translate3d(0, 0, 0) !important;
   transform: translate3d(0, 0, 0) !important;
 }
}
.animate__fadeInLeft {
 -webkit-animation-name: fadeInLeft;
 animation-name: fadeInLeft;
}

@keyframes fadeIn { 
 from { opacity: 0; } 
}

.animate-flicker {
   animation: fadeIn 1s infinite alternate;
   font-size: 32px;
   margin-right: 15px;
   float: left;
   color: #ffcc00;
}


/* Lesson path */
.lessons-page .header-block
{
 margin: 5px 0px 5px 0px !important;
}

.learning-path-container
{
 position: relative;
 width: 1320px;
 height: 700px;
 display: inline-block;
}

.learning-path-png
{
 width: 1320px;
 height: 700px;
 position: absolute;
 top: 0px;
 left: 0px;
 z-index: 1;  
 background-image: url("./assets/learning_path_xl.png");
 background-size: 100%;
 background-repeat: no-repeat;
 background-clip: border-box;
}


.lesson-btn
{
   width: 120px;
   height: 120px;
   background: rgb(255, 196, 0);
   border: 10px solid rgb(255, 255, 255);
   border-radius: 50%;
   z-index: 10;  
   box-shadow: 1px 15px 11px -2px rgba(0,0,0,0.75);
   -webkit-box-shadow: 1px 15px 11px -2px rgba(0,0,0,0.75);
   -moz-box-shadow: 1px 15px 11px -2px rgba(0,0,0,0.75);
   font-size: 20px;
   color: #222;
   font-weight: bold;
   text-shadow: 0px -1px 0px rgba(255,255,255,.5);
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.lessonReward
{
  position: absolute;  
}

.lessonLockBackground
{
  background: rgba(2,2,2,0.6);
  position: absolute;  
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 10px solid rgb(255, 255, 255);
}

.lessonLockSign
{
   
  position: absolute;  
  font-size: 35px;
  color: #fff;
}

.lesson-btn-active
{
   width: 150px;
   height: 150px;
   background: rgb(255, 196, 255, 0.2);
   border: 12px solid rgb(255, 255, 255);
   border-radius: 50%;
   z-index: 10;  
}


.lesson-1
{
  position: absolute;
  left: 1%;
  top: 40%;
}

.lesson-2
{
  position: absolute;
  left: 15%;
  top: 15%;
  background: rgb(240, 79, 119);
}
.lesson-3
{
  position: absolute;
  left: 12%;
  top: 60%;
  background: rgb(96, 33, 133);
  color: #fff;
}
.lesson-4
{
  position: absolute;
  left: 30%;
  top: 40%;
  background: #1abc9c !important;
}
.lesson-5
{
  position: absolute;
  left: 45%;
  top: 12%;
  background: #e74c3c !important;
}
.lesson-6
{
  position: absolute;
  left: 40%;
  top: 62%;
  background: #34495e !important;
}
.lesson-7
{
  position: absolute;
  left: 60%;
  top: 40%;
  background: #74b9ff !important;
}

.lesson-8
{
  position: absolute;
  left: 75%;
  top: 16%;
  background: #a29bfe !important;
}

.lesson-9
{
  position: absolute;
  left: 75%;
  top: 65%;
  background: #f19066 !important;
}

.lesson-10
{
  position: absolute;
  left: 88%;
  top: 30%;
  background: #63cdda !important;
}

.lesson-11
{
  position: absolute;
  left: 86%;
  top: 60%;
  background: #f5cd79 !important;
}

.lesson-12
{
  position: absolute;
  left: 92%;
  top: 25%;
  background: #6ab04c !important;
}

/* Welcome Screen */
.lesson-welcome-images
{
 width: 100%;
 height: auto; 
 background: rgba(2,2,2,0.8);
 overflow: hidden;
 position: relative;
}

.stim-video {
  width: 100%; 
  height: 100%; 
  background-size: cover;
  object-fit: fill; /* May not work with all browsers */
}

.lesson-welcome-script
{
 height: auto;
 width: 100%;
 background: rgba(2,2,2,0.8);
 padding: 20px;
 font-size: 32px;
 color: #fff;
 /* min-height: 250px; */
 display: flex;
 justify-content: center;
 align-items: center;
}

.audio-response-choices
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 100px;
}

.audio-response-choices img
{
  height: 100px;
}

.image-text-audio-block
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-text-audio-block img
{
  height: 50px;
}

.image-audio-response-choices
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.image-audio-response-choices img
{
  height: 50px;
}

.image-audio-response-choices   .default-text-button
{
  font-size: 22px;
  height: 55px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.header-middle-button
{
  max-width: 250px;
  margin-left: calc(50% - 125px);
}

.review_modal .modal-header
{
  border: 0px solid rgba(0,0,0,.2);
  position: absolute;
  top: 2px;
  right: 5px; 
  z-index: 999;
}

.review_modal .modal-content
{
  border: 0px solid rgba(0,0,0,.2);
  padding-top: 20px;
  border-radius: 25px;
}

.review_modal .header_title
{
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #76b423;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  margin: auto;
  width: 80%;
}

.review_modal .review-content-block
{
  font-size: 44px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: rgb(219, 243, 248);
  padding: 35px;
  margin-top: 20px;
  border-radius: 8px;
  color: #fff;
  border: 2px solid rgba(25, 144, 180, 0.2);
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 80px;
}

.review_modal .score-title
{
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #76b423;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  margin: auto;
  width: 100%;
}

.text-green
{
  color: #76b423;
}

.review-content-block .score-blocks
{
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.review-content-block .score-icons
{
  height: 75px;  
  margin-right: 20px;

}

.review-content-block .nav-icons
{
  position: absolute;
  bottom: -75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  left: 0px;
}

.review-content-block .nav-icons img
{
  height: 120px;     
  cursor: pointer;
}

.review-content-block .nav-icons .nav-icon-middle
{
  height: 150px;     
}



.review_modal .review-footer
{
  font-size: 44px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0px solid rgba(0,0,0,.2);
  padding: 20px;
  border-radius: 8px; 
  color: #fff;
  margin: auto;
  margin-top: 20px;

}

.review_modal .review-footer .review-image
{
  height: 250px;
}

@media screen and (min-width: 320px) 
{
  .App .container
  {
    margin-top: 0px;
  }

  .lesson-reward-container {
    margin-top: -60px !important;
    padding-bottom: 20px;
  }

  .question-nav-container {
    margin-top: -20px !important;
  }

  .header-block
  {
    background: rgba(2,2,2,0.2);
    border-radius: 12px;
    border: 4px solid #ffcc00;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .play-btn{
    left: 35% !important;
    top: 33% !important;
  }

  .lesson-stim-button {
    font-size: 25px !important;
    min-width: 150px !important;
    padding-bottom: 20px !important;
  }
 
  .default-answer-box
  {
     
    width: 100%;
    height: 170px;
    font-size: 100px;     
    padding: 10px;
    margin: 10px 0px 10px 0px;

  }

  .default-text-button 
  {
    height: 85px;
    font-size: 32px;
    padding: 10px;
    margin: 10px 0px 10px 0px;
  }

  .default-answer-box img
  {
    max-height: 115px;
    margin-bottom: -15px;
    /* position: absolute; */
    top: 30px;
    max-width: 35%;
    object-fit: contain;
  }

  .response-block
  {
    margin-top: 50px;
  }

  .questions-text-button
  {
      margin: 5px;
      font-size: 35px;
      padding: 0px;
  }

  .arrow-buttons 
  {
    height: 60px !important;
  }

  .crossTick
  {
    position: absolute;    
    height: 12px;    
  }

  .links-xs
  {
    display: flex;
  }

  .links-xl
  {
    display: none;
  }

  .lesson-welcome-script 
  {
    font-size: 28px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: unset;
    min-height: 150px !important;
    max-height: 150px;
  }

  .stim-video {
    height: 300px;
  }

  .lesson-welcome-images 
  {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: unset;
  }

  .learning-path-container
  {
    position: relative;
    width:  320px;
    height: 640px;
   
  }

  .learning-path-png
  {
    width:  320px;
    height: 640px;
  }

  .learning-path-png
  {
    background-image: url("./assets/learning_path_xs.png"); 
  }
  .learning-path-png.unit-6
  {
    background-image: url("./assets/learning_path_xs_unit_6.png"); 
  }
  .learning-path-png.unit-3
  {
    background-image: url("./assets/learning_path_xs_unit_3.png"); 
  }

  .lesson-btn
  {
      width: 60px;
      height: 60px;
      border: 3px solid rgb(255, 255, 255);
      font-size: 10px;
  }

  .lesson-btn-outer
  {
    border: 10px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: -1;
    animation: grow 1.5s infinite;
  }

  .lessonReward
  {
    height: 35px;
  }

  .lessonLockBackground
  {
    
    height: 60px;
    width: 60px;  
    border: 3px solid rgb(255, 255, 255);
  }

  .lessonLockSign
  {
    font-size: 15px;
  }

.lesson-btn-active
{
   width: 150px;
   height: 150px;
   background: rgb(255, 196, 255, 0.2);
   border: 12px solid rgb(255, 255, 255);
   border-radius: 50%;
   z-index: 10;  
}

    
  .lesson-1
  {
    position: absolute;
    left: 40%;
    top: 1%;
  }

  .lesson-2
  {
    position: absolute;
    left: 70%;
    top: 10%;
    background: rgb(240, 79, 119);
  }
  .lesson-3
  {
    position: absolute;
    left: 13%;
    top: 16%;
    background: rgb(96, 33, 133);
    color: #fff;
  }
  .lesson-4
  {
    position: absolute;
    left: 50%;
    top: 31%;
  }
  .lesson-5
  {
    position: absolute;
    left: 70%;
    top: 45%;
  }
  .lesson-6
  {
    position: absolute;
    left: 25%;
    top: 40%;
  }
  .lesson-7
  {
    position: absolute;
    left: 20%;
    top: 60%;
  }

  .lesson-8
  {
    position: absolute;
    left: 70%;
    top: 62%;
  }

  .lesson-9
  {
    position: absolute;
    left: 65%;
    top: 78%;
  }

  .lesson-10
  {
    position: absolute;
    left: 17%;
    top: 73%;
  }

  .lesson-11
  {
    position: absolute;
    left: 25%;
    top: 88%;
  }

  .lesson-12
  {
    position: absolute;
    left: 55%;
    top: 95%;
  } */

  .questions-header
  {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  }

  /* Header */
  .header-first
  {

  text-align: center;
  }

  .header-middle
  {
  align-items: center;
  }

  .header-end
  {

  text-align: center;
  }
 
  .answers-meter
  {
    max-width: 70%;
    overflow: hidden;
  }

  .answers-meter .answers-meter-blocks
  {
      width: 200px ;
      height: 60px ;
      background-size: contain;
      
   
      background-repeat: no-repeat;
      
      padding: 10px;
      font-size: 26px;
      color: rgb(51, 51, 51);
      text-shadow: rgb(255 255 255 / 50%) 0px -1px 0px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  

  }

  .answers-meter .answer-boxes
  {
    margin: 1px ;
    width: 20px ;
    height: 20px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;

  }

  .crossTick
  {
    position: absolute;    
    height: 12px;    
  }

  .header-middle-button
  {
    max-width: 250px;
    margin-left: calc(50% - 125px);
  }


  
  .audio-response-choices
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
  }

  .audio-response-choices img
  {
    height: 60px;
  }

  .audio-response-choices   .default-text-button
  {
    font-size: 32px;
    height: 75px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .image-text-audio-block
  {
    flex-direction: row;
  }

   
  
  .image-audio-response-choices
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
  }

  .image-audio-response-choices img
  {
    height: 80px;
  }

  .image-audio-response-choices   .default-text-button
  {
    font-size: 22px;
    height: 55px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .review_modal .header_title
  {
    font-size: 24px;    
    padding: 10px;
  } 

  .review_modal .review-content-block
  {
    padding: 15px 15px 30px 15px;
    margin-bottom: 30px;
  }

  .review_modal .score-title
  {
    font-size: 20px;    
    padding: 10px;
  }  

  .review_modal .score-blocks
  {
    font-size: 20px;    
    padding: 10px;
    margin-bottom: 7px;
    margin-top: 7px;
  } 

  .review-content-block .score-icons
  {
    height: 50px;
    margin-right: 10px;
  } 

  .review-content-block .nav-icons 
  {
    bottom: -50px;         
  }

  .review-content-block .nav-icons img 
  {
    height: 100px;
  }

  .review-content-block .nav-icons .nav-icon-middle
  {
    height: 100px;
  }

  .review_modal .review-footer .review-image 
  {
    height: 200px;
  }

}  


@media screen and (min-width: 768px) 
{

  .lesson-reward-container {
    margin-top: -70px !important;
    padding-bottom: 20px;
  }

  .play-btn{
    left: 42% !important;
    top: 40% !important;
  }
  
  .links-xs
  {
    display: flex;
  }

  .links-xl
  {
    display: none;
  }

  .lesson-welcome-script 
  {
    font-size: 34px;
  }

  .stim-video {
    height: 100%;
  }
 
  .questions-text-button
  {
      margin: 5px;
      font-size: 55px;
      padding: 0px;
  }

  .arrow-buttons 
  {
    height: 100px !important;
  }

  .default-answer-box 
  {
    width: 100%;
    height: 250px;
    font-size: 100px;
    padding: 10px;
    margin: 10px 0px 0px 0px;
  }

  .default-text-button 
  {
    height: 100px;
    font-size: 45px;
    padding: 10px;
    margin: 10px 0px 0px 0px;
  }
  
  .audio-response-choices
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 0px 0px 0px;
  }

  .audio-response-choices img
  {
    height: 100px;
  }

  .audio-response-choices   .default-text-button
  {
    font-size: 32px;
    height: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  

  .image-text-audio-block
  {
    flex-direction: row;
  }

  .image-audio-response-choices
  {
    flex-direction: column;
  }

  .image-audio-response-choices img
  {
    height: 100px;
  }

  .image-audio-response-choices   .default-text-button
  {
    font-size: 22px;
    height: 100px;
    margin-top: 0px;
    margin-bottom: 0px;
  }


  .answers-meter .answers-meter-blocks
  {
      width: 285px ;
      height: 88px ;
      padding: 10px;
      font-size: 26px;
      background-size: 320px 88px;
  }

  .answers-meter .answer-boxes
  {
    margin: 3px ;
    width: 35px ;
    height: 25px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 4px;
  }

  .crossTick
  {
    position: absolute;    
    height: 16px;    
  }

  .default-answer-box img
  {
    max-height: 170px;
    top: 50px;
    max-width: 25%;
    object-fit: contain;
  }

  .review_modal .header_title
  {
    font-size: 40px;    
    padding: 20px;
  } 

  .review_modal .review-content-block
  {
    padding: 35px 35px 50px 35px;
    margin-bottom: 50px;
  }

  .review_modal .score-title
  {
    font-size: 32px;    
    padding: 20px;
  }  

  .review_modal .score-blocks
  {
    font-size: 30px;    
    padding: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
  } 

  .review-content-block .score-icons
  {
    height: 75px;
    margin-right: 20px;
  }

  /* .nav-icons {
    margin-top: -20px;
  } */

  .review-content-block .nav-icons .nav-icons-middle img 
  {
    height: 100px;
  }

  .review_modal .review-footer .review-image 
  {
    height: 275px;
  }
}  


@media screen and (min-width: 992px) 
{
  
    /* Welcome Screen */
  .lesson-welcome-images
  {
    height: 100%;
    width: 100%;
    flex: 1;
    position: relative;
    border-radius: 8%;
    border: 4px solid #ffcc00;
  }

  .lesson-welcome-script
  {
    height: 100%;
    width: 100%;    
    font-size: 30px; 
    flex: 1;
    border-radius: 8%;
    border: 4px solid #ffcc00;
    max-height: unset;
  }

  .question-nav-container {
    margin-top: 20px !important;
  }

  .skip-btn-container {
    border-radius: 10px;
  }
  
  .lesson-reward-container {
    margin-top: -100px !important;
  }

  .header-block
  {
    background: rgba(2,2,2,0.4);
    border-radius: 12px;
    border: 4px solid #ffcc00;
     
    margin-top: 10px;
    margin-bottom: 50px;

  }

  .header-first-btn
  {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  
  .header-middle-btn
  {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  

  .header-end-btn
  {
    width: 100%;
    text-align: center;
    display: inline-block;
  }

  .App .container
  {
    padding-top: 10px;
  }


    /* Header */
  .header-first
  {
   
    text-align: center;
  }

  .header-middle
  {
    align-items: center;
  }

  .header-end
  {
    
    text-align: center;
  }


  

  .questions-header
  {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  }

 
  .answers-meter
  {
    max-width: 70%;
    overflow: hidden;
  }

  .answers-meter .answers-meter-blocks
  {
      width: 240px;
      height: 70px;
      background-size: contain;
  }

  .answers-meter .answer-boxes
  {
    margin: 2px ;
    width: 20px ;
    height: 20px ;
  }

  .crossTick
  {
    position: absolute;    
    height: 12px;    
  }

  .header-middle-button
  {
    max-width: 250px;
    margin-left: calc(50% - 125px);
  }
  
  .response-block
  {
    margin-top: 50px;
    text-align: center;
  }

  .white-box-holder
  {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 300px;
      font-size: 200px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      cursor: pointer;
      display: flex; 
      flex-direction: row;
      justify-content: center;
      align-items:center;
      padding: 10px;
      margin: 10px;
  
  }
 
  .white-box-holder img
  {
    max-height: 250px;
  }

  .learning-path-container
  {
    position: relative;
    width:  640px;
    height: 1280px;
  }

  .learning-path-png
  {
    width:  640px;
    height: 1280px;
  }

  .learning-path-png
  {
    background-image: url("./assets/learning_path_xs.png"); 
  }

  .lesson-btn
  {
      width: 100px;
      height: 100px;
      border: 8px solid rgb(255, 255, 255);
      font-size: 16px;
  }
 

  .lesson-btn-outer
  {
    border: 10px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: absolute;
    z-index: -1;
    animation: grow 1.5s infinite;
  }

  .lessonReward
  {
    height: 65px;
  }

  .lessonLockBackground
  {
    
    height: 120px;
    width: 120px;  
    border: 10px solid rgba(255, 255, 255, 0.9);
  }

  .lessonLockSign
  {
    font-size: 45px;
    color: rgba(255, 255, 255, 1);
  }

  .lesson-1
  {
    position: absolute;
    left: 40%;
    top: 1%;
  }

  .lesson-2
  {
    position: absolute;
    left: 70%;
    top: 10%;
    background: rgb(240, 79, 119);
  }
  .lesson-3
  {
    position: absolute;
    left: 13%;
    top: 16%;
    background: rgb(96, 33, 133);
    color: #fff;
  }
  .lesson-4
  {
    position: absolute;
    left: 50%;
    top: 31%;
  }
  .lesson-5
  {
    position: absolute;
    left: 70%;
    top: 45%;
  }
  .lesson-6
  {
    position: absolute;
    left: 25%;
    top: 40%;
  }
  .lesson-7
  {
    position: absolute;
    left: 13%;
    top: 58%;
  }

  .lesson-8
  {
    position: absolute;
    left: 55%;
    top: 61%;
  }

  .lesson-9
  {
    position: absolute;
    left: 70%;
    top: 75%;
  }

  .lesson-10
  {
    position: absolute;
    left: 25%;
    top: 75%;
  }

  .lesson-11
  {
    position: absolute;
    left: 15%;
    top: 88%;
  }


  .default-answer-box
  {
      width: 100%;
      height: 200px;
      font-size: 150px;
  }    

  .questions-text-button
  {
      font-size: 35px;
      margin: 10px 10px 30px 10px;
      padding: 10px 10px;      
  }

  .arrow-buttons
  {
    height: 75px !important;
  }
 
  .default-answer-box img
  {
    max-height: 125px;
    top: 40px;
    max-width: 25%;
    object-fit: contain;
  }
}

@media screen and (min-width: 1200px) 
{

  body {
    margin: 0;  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url(assets/bg.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  code {
    font-family: "source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace";
  }

  body, html {
    height: 100%;
    margin: 0;
  }

  .lesson-stim-button {
    font-size: 38px !important;
    min-width: unset !important;
  }

  .App .container
  {
    padding-top: 10px;
  }

  .header-block
  {
    margin-bottom: 150px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .default-list-button
  {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      
      font-size: 26px;
      margin: 10px;
      padding: 10px 10px;
      color: #333;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .default-answer-box img
  {
    max-height: 175px;
    top: 70px;
    max-width: 50%;
    object-fit: contain;
  }

  .questions-text-button
  {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    
      font-size: 65px;
      margin: 10px 10px 50px 10px;
      padding: 10px 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);    
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .header-side-buttons
  {
      
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      font-size: 32px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      cursor: pointer;
  }

  .header-first-btn
  {
    width: 50%;
    text-align: center;
    display: inline-block;
  }
  
  .header-middle-btn
  {
    width: 80%;
    text-align: center;
    display: inline-block;
  }
  

  .header-end-btn
  {
    width: 50%;
    text-align: center;
    display: inline-block;
  }
  
  .header-disabled-button
  {
      color: #bbb !important;
      cursor: default !important;
  }

  .header-middle-button
  {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;    
      font-size: 46px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      cursor: pointer;
  }

  /* Header */
  .header-first
  {
  
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header-first-btn 
  {
    width: 100%;
    text-align: center;
    display: inline-block;
  }

  .header-middle
  {
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-end
  {
  
    text-align: right;
    display: flex;
      justify-content: flex-end;
      align-items: center;
  }

  .header-end-btn {
    width: 100%;
    text-align: center;
    display: inline-block;
  }

  /* Questions Screen */
  .questions-header
  {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  }

  .audio-button, .lessons-link, .answers-meter
  {
    display: inline-block;
  }

  .audio-button img
  {
    width: 75px;
    cursor: pointer;
  
  }

  .links-xs
  {
    display: none !important;
  }

  .links-xl
  {
    display: flex;
  }

  .default-answer-box
  {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 300px;
      font-size: 100px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      
      display: flex; 
      flex-direction: row;
      justify-content: center;
      align-items:center;
      padding: 10px;
      margin: 10px;
  
  }

  .unanswered-answer-box
  {
      background-image: url("./assets/answer_box.png") !important;
      cursor: pointer;
  }

  .unanswered-answer-box:active:hover 
  {
    background-image: url("./assets/answer_box_pressed.png") !important;
    cursor: pointer;
  }




  .answer-boxes
  {
    position: relative;
  }

  .crossTick
  {
    position: absolute;
    left: 1px;
    height: 18px;
    top: 1px;
  }

  .default-text-button
  {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100px;
      font-size: 55px;
      margin: 10px;
      padding: 10px 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .unanswered-text-button
  {
      background-image: url("./assets/button_normal.png");  
  }

  .unanswered-text-button:active:hover 
  {
    background-image: url("./assets/button_pressed.png");
  }


  @-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-50%, 0, 0) !important;
      transform: translate3d(-50%, 0, 0) !important;
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) !important;
      transform: translate3d(0, 0, 0) !important;
    }
  }
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-50%, 0, 0) !important;
      transform: translate3d(-50%, 0, 0) !important;
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) !important;
      transform: translate3d(0, 0, 0) !important;
    }
  }
  .animate__fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }

  @keyframes fadeIn { 
    from { opacity: 0; } 
  }

  .animate-flicker {
      animation: fadeIn 1s infinite alternate;
      font-size: 32px;
      margin-right: 15px;
      float: left;
      color: #ffcc00;
  }


  /* Lesson path */
  .lessons-page .header-block
  {
    margin: 5px 0px 5px 0px !important;
  }

  .learning-path-container
  {
    position: relative;
    width: 1320px;
    height: 700px;
    display: inline-block;
  }

  .learning-path-png
  {
    width: 1320px;
    height: 700px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;  
    background-image: url("./assets/learning_path_xl.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-clip: border-box;
  }

  .learning-path-png.unit-6
  {
    background-image: url("./assets/learning_path_xl_unit_6.png");
  }
  .learning-path-png.unit-3
  {
    background-image: url("./assets/learning_path_xl_unit_3.png");
  }
  
  .lesson-btn
  {
      width: 120px;
      height: 120px;
      background: rgb(255, 196, 0);
      border: 10px solid rgb(255, 255, 255);
      border-radius: 50%;
      z-index: 10;  
      box-shadow: 1px 15px 11px -2px rgba(0,0,0,0.75);
      -webkit-box-shadow: 1px 15px 11px -2px rgba(0,0,0,0.75);
      -moz-box-shadow: 1px 15px 11px -2px rgba(0,0,0,0.75);
      font-size: 20px;
      color: #222;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(255,255,255,.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

  .lesson-btn-outer
  {
    border: 20px solid rgba(200, 255, 200, 0.4);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    z-index: -1;
    animation: grow 1.5s infinite;
  }

  .lesson-btn-active
  {
      width: 150px;
      height: 150px;
      background: rgb(255, 196, 255, 0.2);
      border: 12px solid rgb(255, 255, 255);
      border-radius: 50%;
      z-index: 10;  
  }


  .lesson-1
  {
    position: absolute;
    left: 1%;
    top: 40%;
  }

  .lesson-2
  {
    position: absolute;
    left: 15%;
    top: 15%;
    background: rgb(240, 79, 119);
  }
  .lesson-3
  {
    position: absolute;
    left: 12%;
    top: 60%;
    background: rgb(96, 33, 133);
    color: #fff;
  }
  .lesson-4
  {
    position: absolute;
    left: 30%;
    top: 50%;
  }
  .lesson-5
  {
    position: absolute;
    left: 35%;
    top: 12%;
  }
  .lesson-6
  {
    position: absolute;
    left: 44%;
    top: 40%;
  }
  .lesson-7
  {
    position: absolute;
    left: 50%;
    top: 67%;
  }

  .lesson-8
  {
    position: absolute;
    left: 60%;
    top: 36%;
  }

  .lesson-9
  {
    position: absolute;
    left: 75%;
    top: 15%;
  }

  .lesson-10
  {
    position: absolute;
    left: 72%;
    top: 50%;
  }

  .lesson-11
  {
    position: absolute;
    left: 86%;
    top: 60%;
  }

  .lesson-12
  {
    position: absolute;
    left: 92%;
    top: 25%;
  }

  /* Welcome Screen */
  .lesson-welcome-images
  {
    height: 450px;
    width: 100%;
    border: 4px solid #ffcc00;
    border-radius: 8%;
    background: rgba(2,2,2,0.8);
    overflow: hidden;
    position: relative;
  }

  .lesson-welcome-script
  {
    height: 450px;
    width: 100%;
    border: 4px solid #ffcc00;
    border-radius: 8%;
    background: rgba(2,2,2,0.8);
    padding: 20px;
    font-size: 42px;
    color: #fff;
  }

  .image-text-audio-block
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .image-text-audio-block img
  {
    height: 80px;
  }

  .image-audio-response-choices
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 60px;
  }

  .image-audio-response-choices img
  {
    height: 80px;
  }

  .image-audio-response-choices   .default-text-button
  {
    font-size: 22px;
    height: 90px;
    margin-top: 0px;
    margin-bottom: 0px;
  }


  .answers-meter
  {
    max-width: 100%;
    overflow: hidden;
  }

  .answers-meter .answers-meter-blocks
  {
      background-repeat: no-repeat;
      width: 320px;
      height: 90px;
      padding: 10px;
      font-size: 26px;
      color: rgb(51, 51, 51);
      text-shadow: rgb(255 255 255 / 50%) 0px -1px 0px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }

  .answers-meter .answer-boxes
  {
    margin: 2px;
    border: 2px solid rgb(255, 255, 255);
    width: 25px;
    height: 25px;
    border-radius: 4px;

  }

  .crossTick
  {
    position: absolute;
    left: 1px;
    height: 18px;
    top: 1px;
  }

  .header-middle-button
  {
      height: 100px;
     
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      max-width: 350px;
  }
  

}

.selectedBorder {
  border-color: #FFCE1C !important;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.version {
  z-index: 1;
  color: white;
  font-size: m-small;
  position: fixed;
}

@keyframes grow {
  0% {
    -webkit-transform: scale( 0.9 );-moz-transform: scale( 0.9 );-o-transform: scale( 0.9 );-ms-transform: scale( 0.9 );transform: scale( 0.9 );
  }
   
  50% {
    -webkit-transform: scale( 1.0 );-moz-transform: scale( 1.0 );-o-transform: scale( 1.0 );-ms-transform: scale( 1.0 );transform: scale( 1.0 );
  }
   
  100% {
    -webkit-transform: scale( 0.9 );-moz-transform: scale( 0.9 );-o-transform: scale( 0.9 );-ms-transform: scale( 0.9 );transform: scale( 0.9 );
  }
 }

 .audio-button-incorrect {
   opacity: 0.5;
   cursor: unset !important;
 }

 .play-btn{
  width: 100px;
  height: auto;
  position: absolute;
  left: 40%;
  top: 43%;
  background: none;
  border: none;
}

.lesson-welcome-images .play-btn-img{
  width: 100%;
}

@keyframes sidebounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(59%);
  }
  60% {
    transform: translateX(15%);
  }
}

@keyframes short_sidebounce {
  0% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(20%);
  }
  50% {
    transform: translateX(0px);
  }
  60% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(0px);
  }
}

.arrow-bounce {
  animation: sidebounce 1.5s infinite;
}

.review-next-bounce {
  animation: short_sidebounce 1.5s infinite;
}

@media (min-height: 768px)
{
    .links-xs 
    {
      width: 100%;
      bottom: 30px;
      position: absolute;   
      margin-top: 5px !important;
      left: 0px;
    }

}

@media (max-height: 680px)  and (orientation: portrait) 
{
    .links-xs 
    {
      width: auto;
      bottom: 0px;
      position: relative;   
      margin-top: 5px !important;
    }

}

@media (max-height: 640px)  and (orientation: portrait) 
{
    .links-xs 
    {
      width: auto;
      position: relative;
      bottom: 0px;     
      margin-top: 5px !important;
    }
}

.header-toggle {
  cursor: pointer;
  margin-top: 10px;
}

.nav-disabled {
  opacity: 0.5;
}

.lesson-stim-button {
  color:#00005c;
  font-size: 38px;
  width: 60%;
  background-color:#ffffff00;
  border: none;
  background:radial-gradient(#ffcc01, transparent);
  border-radius: 25px;;
}

.lesson-stim-button:hover {
  background-color: transparent !important;
}

.score-card-lesson-button {
  box-shadow: unset !important;
  position: unset !important;
}

.lesson-reward-container {
  display: flex;
  justify-content: center;
  margin-top: -100px;
}

/* 
  For sweetalert2, backdrop doesn't seem to cover entire background
  on certain screen sizes
*/
.modal-backdrop.show {
  width: 100%;
  height: 100%;
}

.review-card-header-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -20px;
}

.review-card-title {
  font-size: 32px;
  padding-bottom: 10px;
}

.score-text {
  margin-top: 15px;
}

.responsive-font-style-1 {
  font-size: calc(20px + 2vmin);
}

.responsive-font-style-2 {
  font-size: calc(7px + 1.5vmin);
}

.responsive-font-style-3 {
  font-size: calc(14px + 1.5vmin);
}

.review-card-score-container {
  display: flex;
  justify-content: space-between;
  
}

.time-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.time-container img {
  max-width: 80px;
}

.lesson-welcome-script img {
  height: 100%;
  object-fit: contain;
}

.footer-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}

.question-nav-container {
  display: flex;
  justify-content: center;
  font-size: 62px;
  margin-top: 20px;
  /* margin-top: -40px; */
}

.question-nav-icon {
  cursor: pointer;
}

.question-nav-container svg {
  filter: drop-shadow(0px 5px 5px rgb(167, 143, 13));
}

.nav-container-footer{
  padding-top: 5px;
}

video::-webkit-media-text-track-display {
  font-size: 24px;
}

.video-container {
  display: flex;
  justify-content: center;
}

.audio-play-box {
  width: 60px;
  height: 90px;
  position: absolute;
  cursor: pointer;
}

.audio-select-box {
  width: 60px;
  height: 90px;
  margin-left: 65px;
  position: absolute;
  cursor: pointer;
}

.audio-button-container {
  background-image: url("./assets/button-play-2.png");
  width: 133px;
  height: 125px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: default;
}

.add-new-btn {
  position: absolute;
  top: 25px;
  right: 180px;
}

.add-new-btn-icon {
  font-weight: bold;
  font-size: 22px;
}

.form-title {
  padding: 15px;
}

.form {
  width: 95%;
  margin: auto;
}
.fr-btn {
  float: right;
}

.edit-btn-container {
  position: relative;
  width: 90px;
  height: 5px;
  float: right;
}

.audio-correct-button-container {
  background-image: url("./assets/button-play-1.png");
  width: 133px;
  height: 125px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: default;
}

.action {
  position: fixed;
  top: 20px;
  right: 30px;
}

.action .username-container {
  position: relative;
  border-radius: 25px;
  cursor: pointer;
  background-color: #be4738;
  padding: 10px 15px;
  text-align: center;
}

.action .username-container h4 {
  color: #fff;
}

.action .menu {
  position: absolute;
  top: 120px;
  right: -10px;
  padding: 10px;
  background-color: #fff;
  width: 200px;
  box-sizing: 0 5px 25px rgba(0,0,0,0.1);
  border-radius: 15px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}

.action .menu.active {
  top: 65px;
  visibility: visible;
  opacity: 1;
}

.action .menu::before {
  content: '';
  position: absolute;
  top: -5px;
  right: 28px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
}

.action .menu h6 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 10px 0px;
  color: #888;
}

.action .menu ul li {
  list-style: none;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-left: -25px;
}

.action .menu ul li img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: 0.5s;
}

.action .menu ul li:hover img {
  opacity: 1;
}

.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}

.action .menu ul li:hover a {
  color: #000;
}

/* CSS for Matching Images */
.cord-container {
  position: relative;
  width: 300px;
  height: 280px;
  border-radius: 5px;
  background-color: #fff;
  margin: auto;
}

.cord-container.selected {
  /* width: 280px;
  height: 260px; */
  -webkit-box-shadow: 0 0 10px 5px #888888;
  -moz-box-shadow: 0 0 10px 5px #888888;
  box-shadow: 0 0 10px 5px #888888;
}

.thecard {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}

.thecard.active{
  transform: rotateY(180deg);
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  border-radius: 5px;
}

.cardfront {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardback {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.cardback h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25%;
}

.action .menu.active ul {
  margin-bottom: 0px !important;
}

.action .menu.active ul li {
  border-top: unset;
}