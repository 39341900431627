html {
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
.si-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(../../assets/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.score-header {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.score-header > h1 {
  font-size: 2vw;
  font-weight: bold;
  color: #fff;
}

.game-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-title-container h1 {
  font-size: 12vw;
  font-weight: bold;
  color: #fff;
  position: relative;
}

.game-play-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.play-btn {
  width: 25vw;
  height: 10vw;
  background: url("../../assets/sg-play-button.png");
  background-size: cover;
  border-radius: 10px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  bottom: 10px;
  margin-left: -63px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leader-board-btn {
  margin-left: 20px;
  height: 50%;
}

.game-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-counter {
  position: absolute;
  width: 10vw;
  height: 10vw;
  background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.score-counter > h1 {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

.game-board-animation {
  animation: rotate 2s ease-in-out;
}

.game-board-row {
  width: 99%;
  margin-left: 3px;
}

@keyframes rotate {
  to {
    transform: rotateZ(3240deg);
  }
}

.left-top-triangle {
  height: 20vw;
  width: 20vw;
  background-color: #2ecc40;
  border-top-left-radius: 90%;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.left-top-triangle:active {
  box-shadow: -20px 0px 30px 0px #2ecc40;
}

.left-top-triangle img {
  padding-top: 10px;
  padding-left: 10px;
}

.left-right-triangle {
  height: 20vw;
  width: 20vw;
  background-color: #ff4136;
  border-top-left-radius: 20px;
  border-top-right-radius: 90%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.left-right-triangle img {
  padding-top: 10px;
  padding-right: 10px;
}

.left-right-triangle:active {
  box-shadow: 20px 0px 30px #ff4136;
}

.bottom-left-triangle {
  height: 20vw;
  width: 20vw;
  background-color: #0074d9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 90%;
  border-bottom-right-radius: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bottom-left-triangle img {
  padding-bottom: 10px;
  padding-left: 10px;
}

.bottom-left-triangle:active {
  box-shadow: -10px 20px 30px #0074d9;
}

.bottom-right-triangle {
  height: 20vw;
  width: 20vw;
  background-color: #ffdc00;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 90%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bottom-right-triangle img {
  padding-bottom: 10px;
  padding-right: 10px;
}

.bottom-right-triangle:active {
  box-shadow: 10px 20px 30px #ffdc00;
}

.zoomIn {
  animation: zoomInOut 1s;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.09);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.game-result-container {
  position: absolute;
  top: 30%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.game-result-container h1 {
  font-size: 45px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  padding-left: 60px;
}

.game-result-container h3 {
  font-size: 30px;
  color: #fff;
  padding-left: 60px;
}

.game-result-container button {
  height: 120px;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 50px;
  font-weight: bold;
  background: transparent;
  color: #fff;
  border: 3px solid #fff;
}

.text-frmt {
  font-size: 12vw;
  color: #fff;
  font-weight: bold;
}

.disable {
  opacity: 0.7;
  pointer-events: none;
}

.remove-user-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 730px) {
  .text-frmt {
    font-size: 18vw;
  }
  .score-header > h1 {
    font-size: 5vw;
  }
  .play-btn {
    height: 25vw;
    width: 63vw;
  }
  .game-container {
    margin-top: 70px;
  }
  .left-top-triangle {
    width: 40vw;
    height: 40vw;
  }
  .left-right-triangle {
    width: 40vw;
    height: 40vw;
  }
  .bottom-left-triangle {
    width: 40vw;
    height: 40vw;
  }
  .bottom-right-triangle {
    width: 40vw;
    height: 40vw;
  }
  .score-counter {
    width: 30vw;
    height: 30vw;
  }
}
